////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Node
@node_path: "../../../node_modules/";
@lib_path: "./lib/";

// Font Awesome
//@import "@{node_path}font-awesome/less/font-awesome.less";
// Core variables and mixins
@import "@{node_path}bootstrap/less/variables.less";
@import "@{node_path}bootstrap/less/mixins.less";

// Reset and dependencies
@import "@{node_path}bootstrap/less/normalize.less";
//@import "@{node_path}bootstrap/less/print.less";
//@import "@{node_path}bootstrap/less/glyphicons.less";

// Core CSS
@import "@{node_path}bootstrap/less/scaffolding.less";
@import "@{node_path}bootstrap/less/type.less";
//@import "@{node_path}bootstrap/less/code.less";
@import "@{node_path}bootstrap/less/grid.less";
@import "@{node_path}bootstrap/less/tables.less";
@import "@{node_path}bootstrap/less/forms.less";
@import "@{node_path}bootstrap/less/buttons.less";

// Components
@import "@{node_path}bootstrap/less/component-animations.less";
@import "@{node_path}bootstrap/less/dropdowns.less";
@import "@{node_path}bootstrap/less/button-groups.less";
//@import "@{node_path}bootstrap/less/input-groups.less";
@import "@{node_path}bootstrap/less/navs.less";
@import "@{node_path}bootstrap/less/navbar.less";
@import "@{node_path}bootstrap/less/breadcrumbs.less";
//@import "@{node_path}bootstrap/less/pagination.less";
//@import "@{node_path}bootstrap/less/pager.less";
//@import "@{node_path}bootstrap/less/labels.less";
//@import "@{node_path}bootstrap/less/badges.less";
//@import "@{node_path}bootstrap/less/jumbotron.less";
//@import "@{node_path}bootstrap/less/thumbnails.less";
//@import "@{node_path}bootstrap/less/alerts.less";
//@import "@{node_path}bootstrap/less/progress-bars.less";
//@import "@{node_path}bootstrap/less/media.less";
//@import "@{node_path}bootstrap/less/list-group.less";
@import "@{node_path}bootstrap/less/panels.less";
//@import "@{node_path}bootstrap/less/responsive-embed.less";
//@import "@{node_path}bootstrap/less/wells.less";
@import "@{node_path}bootstrap/less/close.less";

// Components w/ JavaScript
@import "@{node_path}bootstrap/less/modals.less";
//@import "@{node_path}bootstrap/less/tooltip.less";
//@import "@{node_path}bootstrap/less/popovers.less";
//@import "@{node_path}bootstrap/less/carousel.less";

// Utility classes
@import "@{node_path}bootstrap/less/utilities.less";
@import "@{node_path}bootstrap/less/responsive-utilities.less";

/* Example how add custom style from libs */
@import (inline) "@{lib_path}slick.css";

@import "www/media/css-src/parameter-mixins";
@import "www/media/css-src/helped-classes";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@font-family-roboto-slab: 'Roboto Slab', serif;
@font-family-base: @font-family-roboto-slab;

@color-pink: #b286ba;
@color-blue: #13528c;
@color-blue-grey: #496a91;
@color-yellow: #fee880;


//== Breadcrumbs
@breadcrumb-padding-vertical:   5px;
@breadcrumb-padding-horizontal: 0;
@breadcrumb-bg:                 transparent;
@breadcrumb-color:              @color-pink;
@breadcrumb-active-color:       #898989;
@breadcrumb-separator:          ">";
.block-breadcrambs {
  .text-center;
  height: 70px;
  margin-bottom: 20px;
  @media(min-width: 1200px) {
    height: 30px;
  }
}
.breadcrumb {
  margin-bottom: 0;
  > li > a, > li > span {
    .text-uppercase;
    color: @breadcrumb-color;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1.95px;
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  > li:last-child > a, > li:last-child > span, > .active {
    color: @breadcrumb-active-color;
  }
}

//== Panels
@panel-bg:                    #fff;
@panel-body-padding-xs:       5px 10px;
@panel-body-padding:          10px 28px;
@panel-heading-padding-xs:    10px 0;
@panel-heading-padding:       15px 0;
@panel-border-radius:         0;

//** Border color for elements within panels
@panel-inner-border:          transparent;

@panel-default-text:          #2c2c2c;
@panel-default-border:        transparent;
@panel-default-heading-bg:    #ffffff;
.panel-group  {
  padding-left: 10px;
  .panel:last-child {
    .panel-collapse {
      .panel-body {
        border-bottom: 1px solid #d4d4d4;
      }
    }
  }
  .panel + .panel {
    margin-top: 0;
    border-top: 1px solid #d4d4d4;
  }
  .panel {
    box-shadow: none;
  }
  .panel-heading {
    padding: @panel-heading-padding-xs;
  }

  .panel-title > a {
    color: #003777;
    font-size: 13px;
    line-height: 1.3 !important;
    letter-spacing: 0.4px;
    span {
      display: inline-block;
      vertical-align: top;
      width: 90%;
      padding-left: 10px;
    }
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  .fa-question-circle {
    margin-left: -8px;
    color: #a7a7a7;
  }
  .panel-body {
    margin-bottom: -1px;
    padding: @panel-body-padding-xs;
    border-left: 1px solid #d4d4d4;
    p {
      display: block;
      color: @panel-default-text;
      font-size: 12.5px;
      font-weight: 300;
      letter-spacing: 0.38px;
    }
  }
  @media (min-width: @screen-sm-min) {
    .panel-heading {
      padding: @panel-heading-padding;
    }
    .panel-title > a {
      font-size: 18px;
    }
    .panel-body {
      p {
        padding: @panel-body-padding;
        font-size: 16px;
      }
    }
  }
}

//== Tabs
@nav-tab-bg-color:                          #ffffff;
@nav-tab-bg-color-active:                   transparent;
@tab-bg-color:                              #ffffff;
@tab-bg-color-active:                       #ffffff;
@nav-tabs-border-color:                     @tab-bg-color;
@nav-tabs-link-hover-border-color:          @tab-bg-color-active;
@nav-tabs-link-color:                       #13528c;
@nav-tabs-link-color-active:                #ffffff;
@nav-tabs-link-bg-active:                   @nav-tab-bg-color-active;
@nav-tabs-active-link-hover-border-color:   @tab-bg-color-active;
@border-width: 3px;

.nav-tabs {
  .text-center;
  margin-bottom: 10%;
  border-bottom: none;
  > li {
    // Actual tabs (as links)
    .text-left;
    float: none;
    display: inline-block;
    vertical-align: top;
    width: 140px;
    margin-right: -4px;
    margin-left: -4px;
    > a {
      .text-center;
      .text-uppercase;
      color: @nav-tabs-link-color;
      font-size: 15px;
      font-weight: 700;
      border-top: @border-width solid @nav-tabs-border-color;
      border-left: @border-width solid @nav-tabs-border-color;
      border-right: @border-width solid @nav-tabs-border-color;
      border-bottom: @border-width solid @nav-tabs-link-hover-border-color;
      border-radius: 0;
      background-color: @nav-tab-bg-color;
      letter-spacing: 3px;
      &:hover, &:focus, &:active {
        color: @nav-tabs-link-color-active;
        background-color: @nav-tab-bg-color-active;
        border-top: @border-width solid @nav-tabs-link-hover-border-color;
        border-left: @border-width solid @nav-tabs-link-hover-border-color;
        border-right: @border-width solid @nav-tabs-link-hover-border-color;
        border-bottom: @border-width solid @nav-tabs-link-hover-border-color;
      }
    }
    // Active state, and its :hover to override normal :hover
    &.active > a {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 48%;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #353d50;
      }
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 46.44%;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #ffffff;
      }
      &,
      &:hover,
      &:focus {
        color: @nav-tabs-link-color-active;
        background-color: @nav-tabs-link-bg-active;
        border: @border-width solid @nav-tabs-active-link-hover-border-color;
        //border-bottom-color: transparent;
        cursor: default;
      }
    }
    @media (min-width: @screen-sm-min) {
      width: 170px;
      > a {
        font-size: 18px;
      }
    }
  }
}
.tab-pane {
  min-height: 280px;
  background-color: @tab-bg-color;
  &:hover, &:focus, &:active {
    background-color: @tab-bg-color-active;
  }
  &.active {
    background-color: @tab-bg-color-active;
    &:hover, &:focus, &:active {
      background-color: @tab-bg-color-active;
    }
  }
}


// -- Default table styles
.table {
  thead {
    tr th {
      .text-uppercase;
      vertical-align: middle;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 1.07px;
    }
  }
  td, th {
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.33px;
  }
  @media (min-width: @screen-sm-min) {
    thead {
      tr th {
        font-size: 18px;
        &:nth-child(1) {
          padding-left: 15px;
        }
      }
    }
    td {
      font-size: 15px;
      &:nth-child(1) {
        padding-left: 15px;
      }
    }
  }
}
// -- Table row colors
.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #ffffff;
}


.btn-primary {
  .btn-default;
}
.btn-default {
  .maker-btn(1.2em, 2em, 13px, #ffffff,
          #0076ff, none, 0,
          #ffffff, darken(#0076ff, 10%), none,
          #ffffff, darken(#0076ff, 15%), none);
  .text-uppercase;
  font-weight: 700;
  letter-spacing: 1.6px;
  @media (min-width: @screen-sm-min) {
    font-size: 16px;
  }
}
.btn-link:hover, .btn-link:focus {
  text-decoration: none;
}
.btn-info {
  .maker-btn(0.8em, 3.5em, 16px, #000000,
          transparent, 1px solid #000000, 0,
          @color-pink, transparent, 1px solid @color-pink,
          darken(@color-pink, 15%), transparent, 1px solid darken(@color-pink, 15%));
  .text-uppercase;
  font-weight: 300;
  letter-spacing: 1.18px;
}
.btn-modal-question {
  .maker-btn(0, 0, 14px, #ffffff,
          transparent, none, 0,
          #ffffff, transparent, none,
          #ffffff, transparent, none);
  .text-uppercase;
  .pull-right;
  position: absolute;
  top: @line-distance-xs;
  right: @line-distance-xs;
  z-index: 1;
  display: flex;
  width: 40px;
  height: 35px;
  font-weight: 700;
  letter-spacing: 1.18px;
  background: url("../images/modal-question-bg.png") no-repeat;
  background-size: auto 100%;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 1px;
    right: 0;
    left: 0;
    display: block;
    background-color: rgba(255, 255, 255, 0);
    transition: .4s;
  }
  &:hover, &:focus, &:active {
    &:before {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  @media (min-width: @screen-sm-min) {
    top: @line-distance;
    right: @line-distance;
    width: 215px;
    height: 71px;
    padding: 0.8em 0.5em;
    p {
      margin-left: auto;
    }
  }
  @media (min-width: @screen-lg-min) {
    width: 235px;
    height: 71px;
    font-size: 16px;
    p {
      margin-left: auto;
    }
  }
}
.btn-table-services {
  .maker-btn(0.5em, 0em, 20px, @color-blue,
          transparent, none, 0,
          @color-blue, transparent, none,
          @color-blue, transparent, none);
  span {
    display: inline-block;
    vertical-align: middle;
    height: 22px;
    font-size: 14px;
  }
  @media (min-width: @screen-sm-min) {
    padding: 0.5em 0.5em;
    border-radius: 50% !important;
    &:hover, &:focus, &:active {
      box-shadow: 0px 1px 5px 0px rgba(50, 50, 50, 0.3) !important;
    }
  }
}
.mail {
  .make-e-mail(@color-yellow, 16px, none, darken(@color-yellow, 10%), underline);
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  font-weight: 300;
  letter-spacing: 1px;
}

.title-page {
  .make-title(0, 5%, #ffffff, 22px, 700);
  .text-center;
  .text-uppercase;
  letter-spacing: 3px;
  @media (min-width: @screen-sm-min) {
    font-size: 36px;
    letter-spacing: 5.02px;
  }
  @media (min-width: 1400px) {
    font-size: 50px;
    letter-spacing: 9.02px;
  }
}

@form-text-color: #888888;
form {
  .block-center;
  width: 90%;
  max-width: 500px;
  .form-control {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 0;
    padding-left: 0;
    color: @form-text-color;
    font-size: 17px;
    font-weight: 300;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #747474;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    &:hover, &:focus, &:active {
      box-shadow: none;
      border-bottom: 1px solid @color-pink;
    }
  }
  input {
    height: 38px;
  }
  textarea {
    height: 80px !important;
    margin-top: 2vh;
    padding-top: 15px;
    padding-right: 15px !important;
    padding-bottom: 15px;
    padding-left: 15px !important;
    color: @form-text-color;
    font-size: 17px;
    font-weight: 300;
    border: 1px solid #747474 !important;
  }
  .btn-default {
    margin-left: 3px;
  }

  // Placeholder text
  form .form-control {
  .placeholder(@color: @form-text-color) {
    // Firefox
    &::-moz-placeholder {
      color: @color;
      opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder { color: @color; } // Internet Explorer 10+
    &::-webkit-input-placeholder  { color: @color; } // Safari and Chrome
  }
  }
  .required {
    position: relative;
    &:before {
      content: '*';
      position: absolute;
      top: 0;
      left: -15px;
      display: block;
      width: 5px;
      height: 10px;
      color: #ff8585;
    }
  }
  .block-required {
    .text-center;
    .block-center;
    margin-bottom: 20px;
    color: @form-text-color;
    font-size: 17px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0.42px;
    p {
      display: inline-block;
    }
  }
}

.content {
  position: relative;
}
@line-distance-xs: 5px;
@line-distance: 10px;
@line-thickness: 3px;
.block-line-top {
  display: block;
  position: absolute;
  z-index: 3;
  top: @line-distance-xs;
  right: @line-distance-xs;
  left: @line-distance-xs;
  height: @line-thickness;
  border-top: @line-thickness solid @color-blue;
  overflow: hidden;
  @media (min-width: @screen-sm-min) {
    top: @line-distance;
    right: @line-distance;
    left: @line-distance;
  }
}
.block-line-bottom {
  display: block;
  position: absolute;
  z-index: 10;
  bottom: @line-distance-xs;
  right: @line-distance-xs;
  left: @line-distance-xs;
  height: @line-thickness;
  background-color: @color-blue;
  @media (min-width: @screen-sm-min) {
    bottom: @line-distance;
    right: @line-distance;
    left: @line-distance;
  }
}
.block-line-right {
  display: block;
  position: absolute;
  z-index: 10;
  top: @line-distance-xs;
  bottom: @line-distance-xs;
  right: @line-distance-xs;
  width: @line-thickness;
  background-color: @color-blue;
  @media (min-width: @screen-sm-min) {
    top: @line-distance;
    bottom: @line-distance;
    right: @line-distance;
  }
}
.block-line-left {
  display: block;
  position: absolute;
  z-index: 10;
  top: @line-distance-xs;
  bottom: @line-distance-xs;
  left: @line-distance-xs;
  width: @line-thickness;
  background-color: @color-blue;
  @media (min-width: @screen-sm-min) {
    top: @line-distance;
    bottom: @line-distance;
    left: @line-distance;
  }
}

.block-logo {
  .text-center;
  .block-img {
    position: relative;
    width: 41px;
    height: 42px;
  }
  .block-text {
    .hidden-xs;
    .text-center;
    .text-uppercase;
    position: relative;
    display: inline-block;
    margin-top: 12px;
    color: #ffffff;
    p {
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 3px;
    }
    .name {
      font-size: 16px;
      letter-spacing: 4px;
    }
  }
  @media (min-width: @screen-sm-min) {
    .block-img {
      width: 60px;
      height: 61px;
    }
  }
  @media (min-width: @screen-lg-min) {
    .block-img {
      width: 84px;
      height: 85px;
    }
  }
}
.block-logo-small {
  width: 45px;
  height: 46px;
}
.block-social {
  .make-block-social(@color-yellow, 40px, 10px, darken(@color-yellow, 10%));
}
.block-wrapper {
  .block-center;
  width: 90%;
  @media(min-width: @screen-sm-min) {
    width: 90%;
    max-width: 1300px;
  }
}
.block-top {
  position: relative;
  padding-top: 5%;
  padding-bottom: 15%;
  padding-right: 5%;
  padding-left: 5%;
  color: #ffffff;
  .block-logo {
    margin-bottom: 3%;
  }
  >.block-img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .block-text {
    position: relative;
  }
  .desc {
    .block-center;
    .text-center;
    max-width: 800px;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
  }
  @media (min-width: @screen-sm-min) {
    .desc {
      font-size: 15px;
      letter-spacing: 1.5px;
    }
  }
  @media (min-width: @screen-lg-min) {
    .desc {
      font-size: 18px;
    }
  }
}
@block-before-height: 10%;
.block-middle {
  position: relative;
  margin-top: -@block-before-height;
}
.block-servises {
  padding-bottom: 40px;
  .servided-title {
    .text-center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.8px;
    background-color: #fffaa9;
  }
  .servises-list {
    .list-unstyled;
    position: relative;
    z-index: 2;
    padding-top: 15px;
    padding-bottom: 15px;
    .servises-item {
      .text-center;
      margin-bottom: 20px;
      font-size: 14px;
      letter-spacing: 1px;
      &:before {
        .block-center;
        content: '';
        display: block;
        margin-bottom: 10px;
        width: 23px;
        height: 5px;
        background-color: #00aef0;
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .servided-title {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 28px;
      letter-spacing: 3.23px;
    }
    .servises-list {
      display: flex;
      justify-content: space-around;
      padding-top: 30px;
      padding-bottom: 30px;
      .servises-item {
        width: 20%;
        font-size: 18px;
      }
    }
  }
}

// ------------------------------------------------ menu
@menu-icon-size-xs: 40px;
@menu-icon-size: 70px;
@menu-height-xs: 400px;
@menu-height-sm: 550px;
@menu-height: 650px;
.block-menu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: @menu-icon-size-xs + 10px;
  height: @menu-icon-size-xs + 5px;
  overflow: hidden;
  transition: .6s cubic-bezier(0.19, 1, 0.22, 1);
  .block-inner {
    position: absolute;
    top: @line-distance-xs;
    bottom: 0;
    right: @line-distance-xs;
    left: @line-distance-xs;
    padding: 5% 10%;
    background: rgba(19,82,140,0.9);
  }
  ul {
    .list-unstyled;
    .text-center;
  }
  .menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > li {
      width: 50%;
      opacity: 0;
      transform: translateY(80px);
      transition: transform .6s  cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  .menu-link {
    .text-uppercase;
    display: block;
    margin-top: 7%;
    margin-bottom: 7%;
    color: #ffffff;
    font-size: 12px;
    line-height: 1.3 !important;
    letter-spacing: 2.48px;
    &:not(div) {
      &:hover, &:focus, &:active {
        color: lighten(@color-blue, 50%);
        text-decoration: none;
      }
    }
  }
  .block-img {
    .block-center;
    width: 25px;
    margin-bottom: 10px;
  }
  .block-submenu {
    padding-bottom: 1%;
  }
  .submenu-link {
    .text-uppercase;
    display: block;
    height: 22px;
    margin-bottom: 3px;
    padding-top: 4px;
    color: #ffffff;
    font-size: 10px;
    line-height: 1.3 !important;
    letter-spacing: 1.6px;
    &:hover, &:focus, &:active {
      color: lighten(@color-blue, 50%);
      text-decoration: none;
    }
  }
  @media (min-width: @screen-sm-min) {
    width: @menu-icon-size + 20px;
    height: @menu-icon-size + 10px;
    .block-inner {
      top: @line-distance;
      right: @line-distance;
      left: @line-distance;
    }
    .menu-list {
      > li {
        width: 22%;
      }
    }
    .menu-link {
      margin-top: 15%;
      margin-bottom: 5%;
      font-size: 18px;
    }
    .block-img {
      width: 80px;
      height: 65px;
      margin-bottom: 20px;
    }
    .submenu-link {
      height: unset;
      margin-bottom: 10px;
      padding-top: unset;
      font-size: 14px;
      font-weight: 700;
    }
  }
  @media (min-width: 1400px) {
    .menu-link {
      font-size: 33px;
    }
    .submenu-link {
      font-size: 16px;
    }
  }
}
// Open menu styles
.open {
  z-index: 2;
  width: 100%;
  height: @menu-height-xs;
  background-position: 0 -80px;
  cursor: default;
  .menu-list > li {
    opacity:1;
    transform: translateY(0px);
  }
  @media (min-width: @screen-sm-min) {
    height: @menu-height-sm;
  }
  @media (min-width: 1400px) {
    height: @menu-height;
  }
}

// icon menu
@menu-icon-padding-xs: 5px;
@menu-icon-padding: 15px;
@main-color: #F2F2F2;
@spin: rotate(135deg);
@reverse-spin: rotate(-135deg);
@default-spin: rotate(0deg);
@duration: .25s;
@type: ease-in-out;
#menu-icon {
  position: absolute;
  top: @line-distance-xs;
  left: @line-distance-xs;
  z-index: 3;
  width: @menu-icon-size-xs;
  height: @menu-icon-size-xs;
  transform: @default-spin;
  transition: @duration * 2 @type;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    top: @menu-icon-padding-xs;
    left: @menu-icon-padding-xs*1.5;
    width: @menu-icon-size-xs - @menu-icon-padding-xs*3;
    height: 3px;
    background: @main-color;
    border-radius: 9px;
    opacity: 1;
    transform: @default-spin;
    transition: @duration @type;
    &:nth-child(1) {
      top: @menu-icon-padding-xs*2.2;
    }
    &:nth-child(2) {
      top: @menu-icon-padding-xs*3.6;
    }
    &:nth-child(3) {
      top: @menu-icon-padding-xs*5;
    }
  }
  &:hover, &:focus, &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
  @media (min-width: @screen-sm-min) {
    top: @line-distance;
    left: @line-distance;
    width: @menu-icon-size;
    height: @menu-icon-size;
    span {
      top: @menu-icon-padding;
      left: @menu-icon-padding;
      width: @menu-icon-size - @menu-icon-padding*2;
      &:nth-child(1) {
        top: @menu-icon-padding*1.4;
      }
      &:nth-child(2) {
        top: @menu-icon-padding*2.2;
      }
      &:nth-child(3) {
        top: @menu-icon-padding*3;
      }
    }
  }
}
// Menu icon animation
#menu-icon.open span {
  &:nth-child(1) {
    top: @menu-icon-padding-xs*3.8;
    transform: @spin;
  }
  &:nth-child(2) {
    opacity: 0;
    left: 20px;
  }
  &:nth-child(3) {
    top: @menu-icon-padding-xs*3.8;
    transform: @reverse-spin;
  }
  @media (min-width: @screen-sm-min) {
    &:nth-child(1) {
      top: @menu-icon-padding*2.2;
    }
    &:nth-child(2) {
      left: 40px;
    }
    &:nth-child(3) {
      top: @menu-icon-padding*2.2;
    }
  }
}


// ------------------------------------------------ footer
footer {
  padding-top: 4vh;
  padding-bottom: 3vh;
  color: #ffffff;
  background: @color-blue;
  .block-copy-viva {
    display: flex;
    .block-copyright {
      flex: 1;
    }
  }
}
.block-contacts {
  .block-enterprise {
    display: flex;
    margin-bottom: 20px;
    padding-right: 20px;
    .block-name {
      padding-left: 10px;
      p {
        font-size: 12px;
        letter-spacing: 1px;
        &:first-child {
          margin-bottom: 3px;
          font-size: 16px;
          letter-spacing: 1.4px;
        }
      }
    }
  }
  .block-list-adress {
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 50px;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  .phones {
    font-size: 25px;
    letter-spacing: 2px;
  }
  .block-social {
    margin-bottom: 20px;
    padding-left: 50px;
  }
  @media(min-width: @screen-sm-min) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    .block-enterprise {
      width: 45%;
    }
    .block-list-adress {
      flex-direction: column;
      width: 35%;
      padding-left: unset;
    }
    .block-social {
      width: 20%;
      padding-left: unset;
    }
  }
}
.block-adress {
  .adress {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.85px;
  }
  .phones {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.5px;
  }
}
.block-copyright {
  color: #cacaca;
  font-size: 12px;
  letter-spacing: 1.14px;
}

// ---------------------------------------------- index
@block-before-border: 5px solid #fff200;
.index {
  .block-top {
    padding-bottom: 35%;
    .block-text {
      .block-center;
      position: relative;
      width: 90%;
    }
    .subtitle {
      margin-bottom: 5%;
      .col-left {
        .text-center;
        margin-right: 2%;
      }
      .title-top, .title-bottom {
        .text-uppercase;
        color: #444444;
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 5px;
      }
      .title-bottom {
        color: #fff47d;
        span {
          border-bottom: 2px solid #fff47d;
        }
      }
      .title-small {
        .text-center;
        .text-uppercase;
        max-width: 420px;
        padding-top: 5%;
        padding-bottom: 5%;
        color: #fff47d;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 3px;
      }
    }
    .desc {
      .text-uppercase;
      max-width: 300px;
      margin-bottom: 3%;
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 2.13px;
    }
    .task {
      .block-center;
      .text-center;
      max-width: 700px;
      color: white;
      .task-big {
        .text-uppercase;
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 2.13px;
      }
      .task-small {
        font-size: 18px;
        letter-spacing: 1.6px;
      }
    }
    @media (min-width: @screen-sm-min) {
      padding-bottom: 19%;
      .subtitle {
        display: flex;
        align-items: flex-end;
        .col-left {
          .text-right;
          width: 50%;
        }
        .col-right {
          margin-left: 2%;
        }
        .title-small {
          .text-left;
          font-size: 16px;
        }
      }
    }
    @media (min-width: @screen-lg-min) and (min-height: 750px) {
      min-height: 100vh;
    }
    @media (min-height: 750px) {
      .subtitle {
        .title-top, .title-bottom {
          font-size: 36px;
        }
        .title-bottom span {
          border-bottom: 3px solid #fff47d;
        }
        .title-small {
          font-size: 18px;
        }
      }
    }
    @media (min-height: 900px) {
      padding-bottom: 10%;
      .subtitle {
        .title-top, .title-bottom {
          font-size: 60px;
        }
        .title-bottom span {
          border-bottom: 5px solid #fff47d;
        }
      }
    }
  }
  .block-middle {
    margin-top: 0;
    background-color: #ffffff;
    .block-advantages {
      position: relative;
      padding-top: 3%;
    }
    .advantages-title {
      .text-center;
      .text-uppercase;
      position: absolute;
      top: -@block-before-height;
      left: 0;
      right: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: @block-before-height;
    }
    .advantages-list {
      .list-unstyled;
      position: relative;
      z-index: 2;
      margin-bottom: 5%;
      padding-right: 2%;
      padding-left: 3%;
      .advantages-item {
        margin-bottom: 20px;
        .title {
          .text-center;
          .text-uppercase;
          margin-bottom: 10px;
          font-size: 18px;
          letter-spacing: 3.2px;
        }
        .block-img {
          .block-center;
          width: 110px;
          height: 110px;
          margin-bottom: 10px;
        }
        p {
          color: #464646;
          font-size: 15px;
          letter-spacing: 0.67px;
        }
      }
    }
    .block-servises {
      background-color: #f7fafd;
      .servided-title {
        .text-uppercase;
      }
    }
    @media (min-width: @screen-sm-min) {
      .advantages-title {
        top: -@block-before-height*3;
        height: @block-before-height*3;
        padding-top: 2%;
      }
    }
    @media (min-width: @screen-lg-min) {
      .advantages-title {
        .text-center;
        .text-uppercase;
        top: -@block-before-height*4;
        left: 0;
        right: 0;
        width: 100%;
        height: @block-before-height*4;
      }
    }
    @media (min-width: 1600px) {
      margin-top: 0;
    }
  }
  .block-bottom {
    position: relative;
    padding-bottom: 5%;
    .moto-block {
      padding-right: 5%;
      padding-left: 5%;
    }
    .moto-text {
      position: relative;
      margin-top: 10%;
      margin-bottom: 10px;
      padding-top: 8%;
      padding-bottom: 8%;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 1px;
      .fa-quote-left, .fa-quote-right {
        position: absolute;
        display: block;
        font-size: 18px;
      }
      .fa-quote-left {
        top: 0;
        left: 0;
      }
      .fa-quote-right {
        bottom: 0;
        right: 0;
      }
      @media (min-width: @screen-sm-min) {
        .fa-quote-left, .fa-quote-right {
          font-size: 22px;
        }
      }
      @media (min-width: @screen-lg-min) {
        .fa-quote-left, .fa-quote-right {
          font-size: 30px;
        }
      }
    }
    .author {
      margin-bottom: 10px;
      font-size: 16px;
    }
    .position {
      max-width: 400px;
      font-size: 14px;
    }
    @media (min-width: @screen-sm-min) {
      padding-bottom: 0;
      .block-img {
        filter: brightness(0.7);
      }
      .moto-block {
        position: absolute;
        top: 10%;
        bottom: 0;
        right: 5%;
        left: 55%;
        color: #ffffff;
        font-weight: 300;
        padding-right: 0;
        padding-left: 0;
      }
      .moto-text {
        font-size: 20px;
      }
      .author {
        margin-bottom: 20px;
        font-size: 20px;
      }
      .position {
        font-size: 16px;
      }
    }
    @media (min-width: @screen-lg-min) {
      .moto-text {
        font-size: 28px;
        letter-spacing: 2px;
      }
    }
    @media (min-width: 1600px) {
      .moto-text {
        margin-bottom: 50px;
        font-size: 36px;
        letter-spacing: 4.07px;
      }
    }
  }
}
.advantages-title {
  .text-center;
  .text-uppercase;
  padding-top: 3%;
  border-top: @block-before-border;
  border-right: @block-before-border;
  border-left: @block-before-border;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3px;
  background-color: #ffffff;
  @media (min-width: @screen-sm-min) {
    font-size: 22px;
    letter-spacing: 4px;
  }
  @media (min-width: @screen-lg-min) {
    font-size: 28px;
    letter-spacing: 6px;
  }
}
.advantages-list {
  .list-unstyled;
  position: relative;
  z-index: 2;
  margin-bottom: 5%;
  padding-right: 2%;
  padding-left: 3%;
  .advantages-item {
    margin-bottom: 20px;
    .title {
      .text-center;
      .text-uppercase;
      margin-bottom: 10px;
      font-size: 18px;
      letter-spacing: 3.2px;
    }
    .block-img {
      .block-center;
      margin-bottom: 10px;
    }
    p {
      color: #464646;
      font-size: 15px;
      letter-spacing: 0.67px;
    }
  }
  @media (min-width: @screen-sm-min) {
    display: flex;
    justify-content: space-between;
    .advantages-item {
      width: 22%;
      .title {
        .text-left;
      }
    }
  }
}

// ---------------------------------------------- posluhy-page
.posluhy-page {
  .block-top {
    padding-bottom: 20%;
    .block-logo {
      margin-bottom: 2%;
    }
    .title {
      margin-bottom: 2%;
    }
  }
  .block-middle {
    position: relative;
    margin-top: -15%;
  }
  .block-table-services {
    padding-bottom: 10px;
  }
  .table-services-title {
    .text-center;
    .text-uppercase;
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
  }
  .panel {
    .panel-title > a {
      display: flex;
      p {
        display: inline-block;
        vertical-align: top;
        &:not(.number-service) {
          flex: 1;
        }
      }
      .number-service {
        margin-right: 10px;
        font-weight: 700;
      }
    }
    .price-service {
      &:before {
        content: 'Вартість:';
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        color: #2c2c2c;
        font-weight: 700;
      }
    }
  }
  .service-page-text {
    max-width: 900px;
    margin-right: 8%;
    margin-bottom: 8%;
    padding-top: 10px;
    font-size: 13px;
    letter-spacing: 0.52px;
    border-top: 1px solid #bfbfbf;
  }
  @media (min-width: @screen-sm-min) {
    .block-top {
      padding-bottom: 15%;
    }
    .block-middle {
      margin-top: -10%;
    }
    .table-services-title {
      font-size: 23px;
      letter-spacing: 4.36px;
    }
    .table {
      box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.2);
      thead {
        tr th {
          &:nth-child(1), &:nth-child(2) {
            background-color: #fffaac;
          }
          &:nth-child(3) {
            background-color: #daf7a4;
          }
          &:nth-child(4) {
            background-color: #c5f3fd;
          }
          &:nth-child(5) {
            background-color: #f1ecd9;
          }
        }
      }
      td {
        &:nth-child(1) {
          .text-center;
        }
        &:nth-child(2) {
          font-size: 16px;
        }
        &:nth-child(5) {
          .text-center;
          vertical-align: middle;
        }
      }
    }
  }
}

// ---------------------------------------------- komanda-page
.komanda-page {
  padding-bottom: 12vh;
  .item-team {
    margin-bottom: 10%;
    .block-img {
      filter: brightness(0.9);
      transition: .4s;
      &:hover, &:focus, &:active {
        filter: brightness(1);
      }
    }
    .name {
      &:hover, &:hover, &:active {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .list-team {
      display: grid;
      grid-template-columns: repeat(4,minmax(10px,297px));
      grid-column-gap: 5%;
      .item-team:nth-child(even) {
        margin-top: 15%;
      }
    }
  }
}
.item-team {
  .block-img {
    position: relative;
    margin-bottom: 18px;
  }
  .name {
    display: block;
    margin-bottom: 8px;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.4px;
  }
  .employee-text {
    font-size: 14px;
    letter-spacing: 0.31px;
  }
  .position {
    max-width: 280px;
    margin-top: 20px;
    margin-bottom: 5vh;
  }
}

// ---------------------------------------------- zapytannya-vidpovid-page
.zapytannya-vidpovid-page {
  .desc {
    margin-bottom: 5%;
  }
  .text-question {
    .text-center;
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .block-questions {
    padding: 4% 5%;
    text-align: justify;
    background-color: #ffffff;
  }
  @media (min-width: @screen-sm-min) {
    .text-question {
      font-size: 25px;
      letter-spacing: 2.37px;
    }
  }
}

// ---------------------------------------------- dokumenty-page
@slider-height-xs: 60vh;
@slider-height: 70vh;
.dokumenty-page {
  .block-for-slider {
    height: @slider-height-xs;
    padding-bottom: 20px;
  }
  .block-inner {
    .align-center;
    flex-direction: column;
    height: 100%;
  }
  .block-desc {
    position: relative;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.31px;
    background-color: #ffffff;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 20px;
      display: block;
      width: 81px;
      height: 5px;
      background-color: @color-blue;
    }
  }
  .block-list-images {
    display: flex;
    .block-img {
      .block-center;
      width: 50%;
    }
    .block-img + .block-img {
      margin-left: 2%;
    }
    img {
      &:hover, &:focus, &:active {
        cursor: pointer;
      }
    }
  }
  .doc-strahuvannia {
    .block-list-images {
      flex-wrap: wrap;
      .block-img + .block-img {
        margin-left: auto;
      }
      .block-img {
        width: 27%;
        margin-bottom: 10px;
      }
    }
  }
  .doc-sertyficat-auditora {
    .block-list-images {
      flex-wrap: wrap;
      .block-img + .block-img {
        margin-left: auto;
      }
    }
  }
  .doc-sertyficat-auditora-2 {
    .block-list-images {
      .block-img {
        width: 80%;
        margin-bottom: 10px;
      }
    }
  }
  .doc-sertyficat-auditora-3 {
    .block-list-images {
      .block-img {
        width: 60%;
        margin-bottom: 10px;
      }
    }
  }
  .a4 {
    .block-list-images .block-img {
      width: 50%;
      margin-bottom: 10px;
    }
  }
  @media (min-width: @screen-sm-min) {
    .block-for-slider {
      height: @slider-height;
    }
    .block-inner {
      flex-direction: row;
    }
    .block-desc {
      width: 50%;
    }
    .block-list-images {
      .block-img {
        width: 80%;
      }
    }
  }
}
// Styles for slider
.slick-slider {
  height: @slider-height-xs;
  padding-top: 1%;
  padding-right: 10%;
  padding-left: 10%;
  background-color: #ffffff;
  @media (min-width: @screen-sm-min) {
    height: @slider-height;
  }
}
.slick-prev, .slick-next {
  width: 30px;
  height: 30px;
  color: @color-blue;
  font-size: 30px;
  &:hover, &:focus {
    color: lighten(@color-blue, 20%);
  }
  &.slick-disabled {
    color: lighten(@color-blue, 50%);
  }
}
.slick-prev {
  left: 2%;
  &:before {
    display: none;
    content: '\f053';
    font-family: "Font Awesome 5 Solid";
  }
}
.slick-next {
  right: 2%;
  &:before {
    display: none;
    content: '\f054';
    font-family: "Font Awesome 5 Solid";
  }
}
//
.colorbox {
  .block-img {
    .align-center;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

}

// ---------------------------------------------- nashi-kliyenty-page
.nashi-kliyenty-page {
  padding-bottom: 12vh;
  .block-klients {
    margin-bottom: 10vh;
    padding: 5vh 3vw;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.2);
  }
  .item-klients {
    position: relative;
    height: 120px;
    margin-bottom: 50px;
    padding-top: 90px;
    .img {
      position: absolute;
      bottom: 30px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }
  .klients-page-text {
    .block-center;
    .text-center;
    max-width: 900px;
    font-size: 18px;
    letter-spacing: 1.5px;
  }
  @media (min-width: @screen-sm-min) {
    .block-list-klients {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .item-klients {
      width: 28%;
      margin-right: 2%;
      margin-left: 2%;
    }
  }
  @media (min-width: @screen-lg-min) {
    .item-klients {
      .block-img {
        margin-bottom: 20px;
      }
      p {
        font-size: 17px;
      }
    }
  }
}

// ---------------------------------------------- seminary-page
.seminary-page {
  padding-bottom: 12vh;
  .block-top {
    padding-bottom: 33%;
  }
  .block-middle {
    margin-top: -25%;
  }
  .block-choose-town {
    .block-center;
    .text-center;
    .text-uppercase;
    max-width: 870px;
    margin-top: 5%;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
  }
  .block-seminar-schedule {
    margin-bottom: 8vh;
    padding-top: 5vh;
  }
  .title {
    .text-center;
    .block-center;
    max-width: 830px;
    margin-bottom: 10px;
    font-size: 20px;
    letter-spacing: 2px;
    span {
      .text-center;
      font-size: 18px;
      letter-spacing: 1.5px;
    }
  }
  .add-info {
    .text-center;
    .block-center;
    max-width: 850px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.3px;
  }
  .block-table {
    padding: 3vh 4vw;
    background-color: #e1e1e1;
    .title {
      font-weight: 700;
    }
  }
  .table {
    box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.2);
    thead {
      tr th {
        &:nth-child(1) {
          .text-left;
          background-color: #fff100;
        }
        &:nth-child(2) {
          .text-center;
          background-color: #c5f073;
        }
        &:nth-child(3) {
          .text-center;
          background-color: #8ae7fb;
        }
      }
    }
    td {
      &:nth-child(2), &:nth-child(3) {
        .text-center;
      }
    }
  }
  .advantages-title {
    margin-bottom: 30px;
  }
  .advantages-item .block-img {
    width: 65px;
    height: 65px;
    margin-bottom: 10px;
  }
  .advantages-item p {
    text-align: justify;
  }
  .text {
    p {
      .block-center;
      .text-center;
      max-width: 800px;
      margin-top: 15px;
      margin-bottom: 40px;
      font-size: 18px;
      letter-spacing: 1.5px;
    }
    .big {
      font-size: 23px;
      font-weight: 400;
      letter-spacing: 2px;
    }
  }
  @media (min-width: @screen-sm-min) {
    .block-top {
      padding-bottom: 12%;
    }
    .block-middle {
      margin-top: -13%;
    }
    .block-choose-town {
      font-size: 18px;
    }
    .advantages-list {
      flex-wrap: wrap;
      padding-right: 5%;
      padding-left: 5%;
      .advantages-item {
        width: 40%;
      }
    }
  }
  @media (min-width: @screen-md-min) {
    .block-top {
      padding-bottom: 10%;
    }
    .block-middle {
      margin-top: -10%;
    }
    .block-choose-town {
      font-size: 30px;
      letter-spacing: 5px;
    }
  }
}

.anchor-detailed {
  .block-center;
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
  color: #fff3a6;
  font-size: 18px;
  letter-spacing: 1.5px;
  border-bottom: 1px dashed currentColor;
  &:hover, &:focus, &:active {
    border-bottom-color: transparent;
    cursor: pointer;
  }
}
.anchor-detailed-poslugy {
  display: inline;
  color: @color-blue;
  font-size: 16px;
  border-bottom: 1px dashed currentColor;
  &:hover, &:focus, &:active {
    border-bottom-color: transparent;
    cursor: pointer;
  }
}

// ---------------------------------------------- istoriya-page
.istoriya-page {
  padding-bottom: 12vh;
  .block-top {
    padding-bottom: 5%;
    .desc p {
      margin-bottom: 20px;
    }
  }
  .block-middle {
    margin-top: 5%;
  }
  .icon-text {
    .text-center;
    margin-bottom: 30px;
    .icon-istoriya {
      .block-center;
      width: 47px;
      height: 47px;
      margin-bottom: 10px;
    }
    p {
      .text-uppercase;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 2px;
    }
  }
  .block-list-history {
    .item {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .number {
      margin-top: 5px;
      margin-right: 20px;
      padding: 10px;
      color: @color-blue-grey;
      font-size: 30px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 2px;
      border-top: 3px solid @color-blue-grey;
    }
    .text {
      font-size: 14px;
      letter-spacing: 0.5px;
      text-align: justify;
    }
  }
  @media (min-width: @screen-sm-min) {
    .icon-text {
      p {
        font-size: 23px;
        letter-spacing: 4px;
      }
    }
    .block-list-history {
      .item {
        display: flex;
        max-width: 52%;
      }
      .item-right {
        margin-left: auto;
      }
      .number {
        margin-right: 40px;
        padding: 15px;
        font-size: 60px;
        letter-spacing: 3px;
        border-top: 6px solid @color-blue-grey;
      }
      .text {
        font-size: 16px;
        letter-spacing: 1px;
      }
    }
  }
}
@line-width: 51px;
@line-height: 75px;
.block-lines {
  .hidden-xs;
  .block-center;
  display: flex;
  width: 80%;
  max-width: 620px;
  .line-horizontal {
    content: '';
    flex: 1;
    height: @line-height - 1px;
    border-bottom: 5px solid #486a93;
  }
  .line-left-top {
    content: '';
    width: @line-width;
    height: @line-height;
    background: url("../images/line-left-top.png") no-repeat;
  }
  .line-left-bottom {
    content: '';
    width: @line-width;
    height: @line-height;
    margin-top: @line-height - 7px;
    background: url("../images/line-left-bottom.png") no-repeat;
  }
  .line-right-top {
    content: '';
    width: @line-width;
    height: @line-height;
    background: url("../images/line-right-top.png") no-repeat;
  }
  .line-right-bottom {
    content: '';
    width: @line-width;
    height: @line-height;
    margin-top: @line-height - 7px;
    background: url("../images/line-right-bottom.png") no-repeat;
  }
}

// ---------------------------------------------- kontakty-page
.kontakty-page {
  padding-bottom: 12vh;
  .block-top {
    padding-bottom: 5%;
    .desc {
      max-width: 1000px;
    }
  }
  .block-middle {
    margin-top: 5%;
  }
  .requisites {
    margin-bottom: 5px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .phones {
    margin-bottom: 30px;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .fa-envelope {
    .block-center;
    display: block;
    color: @color-yellow;
    font-size: 26px;
  }
  .mail {
    margin-top: 5px;
    font-size: 18px;
  }
  .block-social {
    .text-center;
  }
  @media (min-width: @screen-sm-min) {
    .requisites {
      font-size: 18px;
      letter-spacing: 1.5px;
    }
    .phones {
      font-size: 24px;
      letter-spacing: 2px;
    }
  }
}
.block-list-adress {
  @media (min-width: @screen-sm-min) {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    .block-list-adress-item {
      width: 48%;
    }
  }
}

// ------------------------------- 404
.page-404 {
  .text-center;
  width: 100%;
  height: 100%;
  color: #000000;
  background: url("/content/page_404_bg.jpg") no-repeat;
  background-size: cover;
  .inner-block {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 25%;
    padding-bottom: 15%;
  }
  .block-logo {
    margin-bottom: 5%;
  }
  .title {
    .text-uppercase;
    margin-bottom: 0;
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 3px;
  }
  .b-404 {
    margin-bottom: 6%;
    color: #ffffff;
    font-size: 75px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 20px;
  }
  .text {
    margin-bottom: 5%;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.55px;
  }
  .btn-default {
    margin-top: 15px;
    padding: 0.8em 1.5em;
  }
  @media (min-width: @screen-sm-min) {
    .block-circle {
      .block-center;
      width: 500px;
      height: 500px;
      padding-top: 10%;
      background-color: #284975;
      border-radius: 50%;
    }
    .inner-block {
      width: 60%;
      padding-top: 10%;
    }
    .title {
      font-size: 40px;
      letter-spacing: 8px;
    }
    .b-404 {
      font-size: 120px;
      letter-spacing: 25px;
    }
    .text {
      font-size: 18px;
    }
  }
  @media (min-width: @screen-lg-min) {
    .block-circle {
      width: 600px;
      height: 600px;
      padding-top: 10%;
    }
    .title {
      font-size: 50px;
      letter-spacing: 8px;
    }
    .b-404 {
      font-size: 150px;
      letter-spacing: 25px;
    }
    .text {
      font-size: 22px;
    }
  }
}

// --------------------------------- modal styles
.icon-close {
  .maker-icon-close(40px, 1px, #000000);
}
.md-close {
  position: absolute;
  top: 2%;
  right: 2%;
  display: block;
  padding: 0;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  &:hover, &:focus, &:active {
    box-shadow: none;
    .icon-close {
      &:before, &:after {
        background-color: lighten(#000000, 50%);
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    top: 4vh;
    right: 2%;
  }
}
.modal {
  .modal-dialog {
    width: 80%;
    max-width: 1040px;
    margin-top: 5%;
    margin-right: auto;
    margin-left: auto;
    .modal-content {
      border: none;
      border-radius: 0;
    }
  }
  .modal-body {
    padding: 70px 2vw 10px;
  }
  .modal-title {
    .make-title(0, 30px, #000000, 14px, 700);
    .text-center;
    .text-uppercase;
    position: relative;
    letter-spacing: 4.37px;
    p {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding-right: 10px;
      padding-left: 15px;
      background-color: #ffffff;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
    }
  }
  .modal-subtitle {
    .make-title(0, 30px, #000000, 14px, 400);
    .text-center;
    letter-spacing: 0.37px;
  }
  .btn-default {
    margin-top: 30px;
  }
  @media (min-width: @screen-sm-min) {
    .modal-body {
      padding: 5vh 7vw;
    }
    .modal-title {
      margin-bottom: 50px;
      font-size: 16px;
    }
    .modal-subtitle {
      font-size: 25px;
    }
  }
}

#modal-table-services {
  .block-content {
    .text-center;
  }
  .service-price-text {
    margin-bottom: 10px;
    font-size: 14px;
    letter-spacing: 0.31px;
  }
  .service-price {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }
  .service-desc {
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.33px;
  }
}
#modal-employee {
  .item-team {
    padding-bottom: 5vh;
  }
  @media (min-width: @screen-sm-min) {
    .item-team {
      display: flex;
      .block-photo {
        width: 40%;
        padding-right: 10%;
      }
      .block-desc {
        width: 60%;
        .employee-text {
          text-align: justify;
        }
      }
    }
  }
}
/* Disable scroll */
.disable-scroll {
  margin: 0;
  overflow: hidden;
}

/* Colorbox styles */

.colorbox {
  display: none;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  background:rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100%;
  .block-img {
    .block-center;
    width: 70%;
    max-width: 800px;
    > img {
      width: unset;
      max-width: 100%;
      max-height: 92%;
    }
  }
  .colorbox-image {
    position: relative;
    top: 40%;
    left: 40%;
    width: 400px;
    height: 400px;
  }
  .colorbox-close {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    margin-right: -32px;
    margin-left: auto;
    opacity: 0.6;
  }
  .colorbox-close:hover {
    opacity: 1;
  }
  .colorbox-close:before, .colorbox-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
  }
  .colorbox-close:before {
    transform: rotate(45deg);
  }
  .colorbox-close:after {
    transform: rotate(-45deg);
  }
}

#js-show-message {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1051; /* Sit on top */
  left: 15%;
  top: 37%;
  width: 70%;
  height: 100px;
  .js-message {
    margin: auto;
    display: block;
    width: 100%;
    max-width: 320px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.8); /* Fallback color */
    border: 2px solid #fff;
  }
}
