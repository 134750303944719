// Parameter mixins

// Maker for navbar-default
.make-navbar(@navbar-height, @navbar-bg-color, @navbar-link-padding,
@navbar-link-color, @navbar-link-font-size, @navbar-link-font-weight, @navbar-link-bg-color,
@navbar-link-color-hover, @navbar-link-bg-color-hover,
@navbar-link-color-active, @navbar-link-bg-color-active) {
  min-height: @navbar-height;
  margin-bottom: 0;
  background-color: @navbar-bg-color;
  border: none;
  border-radius: 0;
  .navbar-nav {
    > li > a, > li > p {
      .align-center;
      //height: @navbar-height;
      padding: @navbar-link-padding;
      color: @navbar-link-color;
      font-size: @navbar-link-font-size;
      font-weight: @navbar-link-font-weight;
      background-color: @navbar-link-bg-color;
      &:hover, &:focus {
        color: @navbar-link-color-hover;
        background-color: @navbar-link-bg-color-hover;
      }
      &:active {
        color: @navbar-link-color-active;
        background-color: @navbar-link-bg-color-active;
      }
    }
    > .active > a {
      &, &:hover, &:focus {
        color: @navbar-link-color-active;
        background-color: @navbar-link-bg-color-active;
      }
    }
  }
}
// Maker for navbar header with navbar-brand image
.make-navbar-header-img(@navbar-header-height, @nav-brand-width, @nav-brand-height, @nav-brand-margin,
@toggle-margin, @toggle-padding, @toggle-bg-color, @toggle-border, @toggle-border-radius,
@icon-bar-width, @icon-bar-height, @icon-bar-border-radius, @icon-bar-bg-color, @icon-bar-margin-top,
@toggle-bg-color-active, @toggle-border-active, @icon-bar-bg-color-active) {
  .navbar-header {
    .align-center;
    justify-content: flex-start;
    height: @navbar-header-height;
    .navbar-brand {
      display: block;
      width: @nav-brand-width;
      height: @nav-brand-height;
      margin: @nav-brand-margin;
      padding: 0 !important;
      #logo {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    >.navbar-toggle {
      margin: @toggle-margin;
      margin-left: @toggle-margin;
      padding: @toggle-padding;
      background-color: @toggle-bg-color;
      border: @toggle-border;
      border-radius: @toggle-border-radius;
      .icon-bar {
        width: @icon-bar-width;
        height: @icon-bar-height;
        border-radius: @icon-bar-border-radius;
        background-color: @icon-bar-bg-color;
      }
      .icon-bar + .icon-bar {
        margin-top: @icon-bar-margin-top;
      }
      &:active {
        background-color: @toggle-bg-color-active;
        border: @toggle-border-active;
        .icon-bar {
          background-color: @icon-bar-bg-color-active;
        }
      }
    }
  }
}
// Maker for title
.make-title(@title-margin-top, @title-margin-bottom, @title-color, @title-font-size, @title-font-weight) {
  display: block;
  margin-top: @title-margin-top;
  margin-bottom: @title-margin-bottom;
  color: @title-color;
  font-size: @title-font-size;
  font-weight: @title-font-weight;
}
// Maker for dropdown-menu
.maker-dropdown-menu(@dropdown-width, @dropdown-margin-top, @dropdown-padding, @dropdown-bg,
@dropdown-border, @dropdown-border-radius, @dropdown-toggle-link-padding,
@dropdown-link-padding, @dropdown-link-color, @dropdown-link-font-size, @dropdown-link-font-weight,
@dropdown-link-hover-color, @dropdown-link-hover-bg,
@dropdown-link-active-color, @dropdown-link-active-bg) {
  &.open > .dropdown-toggle.btn-default {
    color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg;
    border: none !important;
  }
  .dropdown-toggle {
    padding: @dropdown-toggle-link-padding;
  }
  .dropdown-menu {
    width: @dropdown-width;
    min-width: @dropdown-width;
    margin-top: @dropdown-margin-top;
    padding: @dropdown-padding;
    background-color: @dropdown-bg;
    border: @dropdown-border;
    border-radius: @dropdown-border-radius;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.175);
    li > a, li > .btn {
      //.align-center;
      display: block;
      padding: @dropdown-link-padding;
      color: @dropdown-link-color;
      font-size: @dropdown-link-font-size;
      font-weight: @dropdown-link-font-weight;
      line-height: 1.1 !important;
      white-space: unset !important;
      &:hover,
      &:focus {
        text-decoration: none;
        color: @dropdown-link-hover-color;
        background-color: @dropdown-link-hover-bg;
      }
    }
    .active > a {
      &,
      &:hover,
      &:focus {
        color: @dropdown-link-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @dropdown-link-active-bg;
      }
    }
  }
}
// Maker for button
.maker-btn(@btn-padding-top, @btn-padding-right, @btn-font-size, @btn-color,
@btn-bg-color, @btn-border, @btn-border-radius,
@btn-hover-color, @btn-hover-bg-color, @btn-border-hover,
@btn-color-active, @btn-bg-color-active, @btn-border-active) {
  padding: @btn-padding-top @btn-padding-right;
  color: @btn-color !important;
  font-size: @btn-font-size;
  line-height: 1 !important;
  background-color: @btn-bg-color !important;
  border: @btn-border !important;
  border-radius: @btn-border-radius !important;
  &:hover, &:focus, &.active {
    color: @btn-hover-color !important;
    background-color: @btn-hover-bg-color !important;
    border: @btn-border-hover !important;
    box-shadow: none !important;
  }
  &:active, &:active:hover {
    color: @btn-color-active !important;
    background-color: @btn-bg-color-active !important;
    border: @btn-border-active !important;
    box-shadow: none !important;
  }
}
// Maker for arrow right
.make-arrow-right(@arrow-width, @arrow-border-width, @arrow-color) {
  width: @arrow-width;
  height: @arrow-width;
  border-top: solid @arrow-border-width @arrow-color;
  border-right: solid @arrow-border-width @arrow-color;
  transform: rotate(45deg);
}
// Maker for arrow left
.make-arrow-left(@arrow-width, @arrow-border-width, @arrow-color) {
  width: @arrow-width;
  height: @arrow-width;
  border-top: solid @arrow-border-width @arrow-color;
  border-right: solid @arrow-border-width @arrow-color;
  transform: rotate(-135deg);
}
// Maker for triangle
.maker-triangle(@triangle-height, @triangle-direction, @triangle-bg-color) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: @triangle-height;
    background: linear-gradient(to @triangle-direction, @triangle-bg-color 50%, transparent 50%);
  }
}
// Maker for close icon
.maker-icon-close(@icon-width, @icon-thick, @icon-color) {
  position: relative;
  display: block;
  //width: @icon-close-width;
  //height: @icon-close-width;
  margin-top: 0;
  margin-left: 0;
  width: @icon-width;
  height: @icon-width;
  &:before {
    content: '';
    position: absolute;
    top: @icon-width / 2;
    left: 0;
    width: @icon-width;
    height: @icon-thick;
    background-color: @icon-color;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: @icon-width / 2;
    left: 0;
    width: @icon-width;
    height: @icon-thick;
    background-color: @icon-color;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
// Maker for e-mail
.make-e-mail(@mail-color, @mail-font-size, @mail-text-decoration, @mail-color-hover, @mail-text-decoration-hover) {
  color: @mail-color;
  font-size: @mail-font-size;
  text-decoration: @mail-text-decoration;
  &:hover, &:focus, &:active {
    color: @mail-color-hover;
    text-decoration: @mail-text-decoration-hover;
  }
}
